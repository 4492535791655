import { Box } from 'components/Box';
import {
  CmsApiSplitBlockAppearance,
  CmsApiSplitBlockText,
} from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import { gutterWidth } from 'theme/theme';

import { CmsSplitContentTextContentInner } from './CmsSplitContentTextContentInner';

type Props = {
  section: CmsApiSplitBlockText;
  appearance: CmsApiSplitBlockAppearance;
  index: number;
  blockId: string;
  imageTextBlock: boolean;
  showBackgroundImage: boolean;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsSplitContentTextContent({
  appearance,
  blockId,
  blockIndex,
  imageTextBlock,
  index,
  pageUpdated,
  section,
  showBackgroundImage,
}: Props) {
  const showTextBox = ['FULL_WIDTH', 'OVERLAPPING'].includes(appearance);

  const hideButton =
    section.data.button &&
    section.data.button.type === 'CMS_PAGE' &&
    !section.data.button.data.pageTitle;

  return (
    <Box
      data-qa-id={`split-content-section-${index}`}
      // @ts-expect-error TS(2322): Type 'false | 1' is not assignable to type 'Respon... Remove this comment to see the full error message
      zIndex={showTextBox && 1}
      width={[1, null, 1 / 2]}
      px={[imageTextBlock ? '24px' : gutterWidth / 4, null, gutterWidth / 2]}
      py={[
        imageTextBlock ? gutterWidth / 2 : gutterWidth / 4,
        null,
        gutterWidth / 2,
      ]}
      key={blockId}
    >
      <CmsSplitContentTextContentInner
        appearance={appearance}
        blockId={blockId}
        blockIndex={blockIndex}
        imageTextBlock={imageTextBlock}
        index={index}
        pageUpdated={pageUpdated}
        label={section.data.label}
        headline={section.data.headline}
        html={section.data.html}
        button={section.data.button}
        showBackgroundImage={showBackgroundImage}
        showTextBox={showTextBox}
        hideButton={Boolean(hideButton)}
      />
    </Box>
  );
}
